import React, {useState, useEffect} from 'react'
import './newOrder.css'
import { FiChevronUp, FiChevronDown, FiArrowRight } from "react-icons/fi";

import Header from '../header/header';
import CuttingListRow from './cuttingListRow';
import MaterialSelect from './popups/materialSelect/materialSelect';
import EdgebandingPopup from './popups/edgebandingPopup/edgebandingPopup';
import MachiningPopup from './popups/machiningPopup/machiningPopup';
import SprayPopup from './popups/sprayPopup/sprayPopup';
import PanelSummary from './popups/panelSummary/panelSummary';
import RemoveConfirmation from './popups/removeConfirmation/removeConfirmation';

import FullSheetRow from './fullSheetRow';
import FullSheetMaterialSelection from '../fullSheetPopups/materialSelection/fullSheetMaterialSelection';
import FullSheetRemovePopup from '../fullSheetPopups/materialSelection/fullSheetRemove/fullSheetRemovePopup';

import EdgetapeRow from './edgetapeRow';
import EdgetapeRemove from '../edgetapePopups/edgetapeRemove';

import { Link } from 'react-router-dom';

import {checkSavedOrderConfig, getRowsConfig, addNewRowInConfig, changeRowWithOpenSelection, removeRow, changeFullSheetRowWithOpenSelection, getFullSheetsConfig, addNewFullSheetsRowInConfig, removeFullSheetsRow, getEdgingTapeConfig, setEdgingTapeRowWithOpenSelection, addNewEdgingTapeRowInConfig, removeEdgingTapesRow } from './orderConfig';


function NewOrder() {
    useEffect(() => {

        checkOrderConfig();

        fetchRowsConfig();
        fetchFullSheetsConfig();
        fetchEdgingTapeConfig();

        const globalRowsConfig = [...getRowsConfig()];
        const globalFullSheetsRowsConfig = [...getFullSheetsConfig()];
        const globalEdgingTapeRowsConfig = [...getEdgingTapeConfig()];
        checkPricingAvailability(globalRowsConfig, globalFullSheetsRowsConfig, globalEdgingTapeRowsConfig);

        return () => {
            // Perform cleanup tasks here
            console.log('Component unmounted');
            setRowsConfig([]);
            setFullSheetsRows([]);
            setEdgetapeList([]);
        };
    }, []);

    const checkOrderConfig = async() =>{
        await checkSavedOrderConfig();
    }

    const [rowsConfig, setRowsConfig] = useState([]);
    const fetchRowsConfig = async () => {
        try {
            // Fetch data from orderConfig.js
            console.log('fetched row config');
            const globalRowsConfig = [...getRowsConfig()];
            setRowsConfig(globalRowsConfig);
            checkPricingAvailability(globalRowsConfig, fullSheetsRows, edgingTapeRows);
            console.log(globalRowsConfig);
        } catch (error) {
            console.log('Error fetching data');
        }
    };

    const addNewRow = () => {
        addNewRowInConfig();
        fetchRowsConfig();
    };

    //  decor selection popup
    const [materialSelectVisible, setMaterialSelectVisible] = useState(false);
    const openMaterialSelect = (rowID) =>{
        changeRowWithOpenSelection(rowID);
        setMaterialSelectVisible(true);
    }
    const closeMaterialSelect = () =>{
        setMaterialSelectVisible(false);
        fetchRowsConfig();
    }
    //  edgebanding popup
    const [edgebandingPopupVisible, setEdgebandingPopupVisible] = useState(false);
    const openEdgebandingPopup = (rowID) =>{
        changeRowWithOpenSelection(rowID);
        setEdgebandingPopupVisible(true);
    }
    const closeEdgebandingPopup = () =>{
        setEdgebandingPopupVisible(false);
        fetchRowsConfig();
    }
    //  machining popup
    const [machiningPopupVisible, setMachiningPopupVisible] = useState(false);
    const openMachiningPopup = (rowID) =>{
        changeRowWithOpenSelection(rowID);
        setMachiningPopupVisible(true);
    }
    const closeMachiningPopup = () =>{
        setMachiningPopupVisible(false);
        fetchRowsConfig();
    }
    //  spray popup
    const [sprayPopupVisible, setSprayPopupVisible] = useState(false);
    const openSprayPopup = (rowID) =>{
        changeRowWithOpenSelection(rowID);
        setSprayPopupVisible(true);
    }
    const closeSprayPopup = () =>{
        setSprayPopupVisible(false);
        fetchRowsConfig();
    }
    //  panel summary popup
    const [summaryPopupVisible, setSummaryPopupVisible] = useState(false);
    const openSummaryPopup = (rowID) =>{
        changeRowWithOpenSelection(rowID);
        setSummaryPopupVisible(true);
    }
    const closeSummaryPopup = () =>{
        setSummaryPopupVisible(false);
        fetchRowsConfig();
    }
    //  remove confirmation popup
    const [removeConfirmationPopupVisible, setRemoveConfirmationPopupVisible] = useState(false);
    const openRemoveConfirmationPopup = (rowID) =>{
        changeRowWithOpenSelection(rowID);
        setRemoveConfirmationPopupVisible(true);
    }
    const closeRemoveConfirmationPopup = () =>{
        setRemoveConfirmationPopupVisible(false);
        fetchRowsConfig();
    }

    const confirmedRemoveRow = () => {
        removeRow();
        fetchRowsConfig();
    };


    //  toggle cutting list
    const [expandCuttingList, setExpandCuttingList] = useState(true);
    const toggleCuttingList = () =>{
        setExpandCuttingList(!expandCuttingList);
    }



// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| FULL SHEETS ROWS|-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [fullSheetsRows, setFullSheetsRows] = useState([]);

    const fetchFullSheetsConfig = () => {
        try {
            // Fetch data from orderConfig.js
            console.log('fetching full sheets config');
            const globalFullSheetsRowsConfig = [...getFullSheetsConfig()];
            setFullSheetsRows(globalFullSheetsRowsConfig);
            checkPricingAvailability(rowsConfig, globalFullSheetsRowsConfig, edgingTapeRows);
            console.log(globalFullSheetsRowsConfig);
        } catch (error) {
            console.log('Error fetching full sheets data');
        }
    };

    // functions to open the material select modal (popup)
    const [fullSheetMaterialModalVisible, setFullSheetMaterialModalVisible] = useState(false);
    const openFullSheetMaterialSelectModal = async(rowID) => {
        await changeFullSheetRowWithOpenSelection(rowID);
        setFullSheetMaterialModalVisible(true);
    };
    const closeFullSheetMaterialSelectModal = () => {
        setFullSheetMaterialModalVisible(false);
    };

    // functions to open the Remove confirmation modal (popup)
    const [fullSheetRemoveConfirmationModalVisible, setFullSheetRemoveConfirmationModalVisible] = useState(false);
    const openFullSheetRemoveConfirmationModal = () => {
        setFullSheetRemoveConfirmationModalVisible(true);
    };
    const closeFullSheetRemoveConfirmationModal = () => {
        setFullSheetRemoveConfirmationModalVisible(false);
    };
    const removeFullSheetRow = (rowID) => {
        changeFullSheetRowWithOpenSelection(rowID);
        openFullSheetRemoveConfirmationModal();
    };
    const confirmedFullSheetRowRemove = () => {
        removeFullSheetsRow();
        fetchFullSheetsConfig();
    };

    const addNewFullSheetsRow = () =>{
        addNewFullSheetsRowInConfig();
        fetchFullSheetsConfig();
    }

    //  toggle full sheets list
    const [expandFullSheetsList, setFullSheetsList] = useState(false);
    const toggleFullSheetsList = () =>{
        setFullSheetsList(!expandFullSheetsList);
    }


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| EDGING TAPE ROWS|-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
    const [edgingTapeRows, setEdgingTapeRows] = useState([]);
    const fetchEdgingTapeConfig = async() => {
        try {
            // Fetch data from orderConfig.js
            console.log('fetching edging tape config');
            const globalEdgingTapeRowsConfig = [...getEdgingTapeConfig()];
            setEdgingTapeRows(globalEdgingTapeRowsConfig);
            checkPricingAvailability(rowsConfig, fullSheetsRows, globalEdgingTapeRowsConfig);
            console.log(globalEdgingTapeRowsConfig);
        } catch (error) {
            console.log('Error fetching edging tape data');
        }
    };

    //  edging tape select modal
    const [edgingTapeSelectModalVisile, setEdgingTapeSelectModalVisible] = useState(false);
    const openEdgingTapeSelectModal = (rowID) => {
        setEdgingTapeRowWithOpenSelection(rowID);
        setEdgingTapeSelectModalVisible(true);
    };
    const closeEdgingTapeSelectModal = () => {
        setEdgingTapeSelectModalVisible(false);
    };

    //  add new edging tape row
    const addNewEdgingTapeRow = () =>{
        addNewEdgingTapeRowInConfig();
        fetchEdgingTapeConfig();
    }

    // functions to open the Remove confirmation modal (popup)
    const [edgingTapeRemoveConfirmationModalVisible, setEdgingTapeRemoveConfirmationModalVisible] = useState(false);
    const openEdgingTapeRemoveConfirmationModal = () => {
        setEdgingTapeRemoveConfirmationModalVisible(true);
    };
    const closeEdgingTapeRemoveConfirmationModal = () => {
        setEdgingTapeRemoveConfirmationModalVisible(false);
    };
    const removeEdgingTapeRow = (rowID) => {
        setEdgingTapeRowWithOpenSelection(rowID);
        openEdgingTapeRemoveConfirmationModal();
    };
    const confirmedEdgingTapeRowRemove = () => {
        removeEdgingTapesRow();
        fetchEdgingTapeConfig();
    };

    //  toggle edging tape list
    const [expandEdgetapeList, setEdgetapeList] = useState(false);
    const toggleEdgetapeList = () =>{
        setEdgetapeList(!expandEdgetapeList);
    }





    //  check if user can move forward to the pricing page
    const [pricingAvailable, setPricingAvailable] = useState(false);
    const checkPricingAvailability = (cuttingListConfig, fullSheetsConfig, edgingTapeConfig) => {
        //  loop trough all the rows config
        if(cuttingListConfig.length > 0 || fullSheetsRows.length > 0){
            //  check the cutting list rows
            if(cuttingListConfig.length > 0){
                for(let i = 0; i < cuttingListConfig.length; i++){
                    if(cuttingListConfig[i].qtyState == false || cuttingListConfig[i].lengthState == false || cuttingListConfig[i].widthState == false){
                        setPricingAvailable(false);
                        return;
                    }
                }
            }
            if(fullSheetsConfig.length > 0){
                //check the full sheets rows
                for(let i = 0; i < fullSheetsConfig.length; i++){
                    if(fullSheetsConfig[i].qtyState == false){
                        setPricingAvailable(false);
                        return;
                    }
                }
            }
            if(edgingTapeConfig.length > 0){
                //check the edging tape rows
                for(let i = 0; i < edgingTapeConfig.length; i++){
                    console.log('Edging tape config qty state:' + i, edgingTapeConfig[i].qtyState);
                    if(edgingTapeConfig[i].qtyState == false){
                        setPricingAvailable(false);
                        return;
                    }
                }
            }
            //  all the checks passed
            setPricingAvailable(true);
        }else{
            setPricingAvailable(false);
        }
    }

    return (
        <div className='section'>
            <Header />
            {/* POPUPS */}
            {materialSelectVisible === true ? (
                <MaterialSelect closeMaterialSelect={closeMaterialSelect} />
            ) : null}
            {edgebandingPopupVisible === true ? (
                <EdgebandingPopup closeMaterialSelect={closeEdgebandingPopup} />
            ) : null}
            {machiningPopupVisible === true ? (
                <MachiningPopup closeMaterialSelect={closeMachiningPopup} />
            ) : null}
            {sprayPopupVisible === true ? (
                <SprayPopup closeSprayPopup={closeSprayPopup} />
            ) : null}
            {summaryPopupVisible === true ? (
                <PanelSummary closeSummaryPopup={closeSummaryPopup} />
            ) : null}
            {removeConfirmationPopupVisible === true ? (
                <RemoveConfirmation closeRemoveConfirmationPopup={closeRemoveConfirmationPopup} removeRow={confirmedRemoveRow}/>
            ) : null}

            <div className='container'>
                {/* CUTTING LIST */}
                <div className='orderSection'>
                    <div className='orderSectionHeader' onClick={() => toggleCuttingList()}>
                        <h4 style={{fontSize: '24px', fontWeight: '400'}}>Cutting list</h4>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', color: '#3a86ff', cursor: 'pointer'}}>
                            <p>{expandCuttingList === true ? 'Collapse' : 'Expand'}</p>
                            {expandCuttingList === true ? 
                                <FiChevronUp /> : <FiChevronDown/> 
                            }
                        </div>
                    </div>
                    {expandCuttingList === true ? (
                        <div className='orderSectionContent'>
                            <div className='cuttingListRowLabels'>
                                <div className='grid_item'><p>#</p></div>
                                <div className='grid_item'><p>Material decor code / name</p></div>
                                <div className='grid_item'><p>Thick [mm]</p></div>
                                <div className='grid_item'><p>Length [mm]</p></div>
                                <div className='grid_item'><p>Width [mm]</p></div>
                                <div className='grid_item'><p>Qty</p></div>
                                <div className='grid_item'><p>Part description</p></div>
                                <div className='grid_item'><p>Edgebanding details</p></div>
                                <div className='grid_item'><p>Additional machining</p></div>
                                <div className='grid_item'><p>Spray coating</p></div>
                                <div className='grid_item'><p>Grain match</p></div>
                                <div className='grid_item'><p>Actions</p></div>
                            </div>
                            {rowsConfig.map((row, index) => (
                                <CuttingListRow 
                                    key={index} 
                                    rowID={index} 
                                    row={row} 
                                    openMaterialSelect={openMaterialSelect} 
                                    openEdgebandingPopup={openEdgebandingPopup} 
                                    openMachiningPopup={openMachiningPopup} 
                                    openSprayPopup={openSprayPopup} 
                                    openSummaryPopup={openSummaryPopup} 
                                    openRemoveConfirmationPopup={openRemoveConfirmationPopup}
                                    fetchRowsConfig={fetchRowsConfig}/>
                            ))}
                            <div className='addRowContainer'>
                                <div className='addRowBtn' onClick={() => addNewRow()}>
                                    <p>Add cutting list row</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* FULL SHEETS LIST */}
                {/* POPUPS */}
                {fullSheetMaterialModalVisible === true ? (
                    <FullSheetMaterialSelection closeMaterialSelect={closeFullSheetMaterialSelectModal} fetchRows={fetchFullSheetsConfig}/>
                ) : null}
                {fullSheetRemoveConfirmationModalVisible === true ? (
                    <FullSheetRemovePopup closeRemoveConfirmationPopup={closeFullSheetRemoveConfirmationModal} removeRow={confirmedFullSheetRowRemove}/>
                ) : null}
                <div className='orderSection'>
                    <div className='orderSectionHeader' onClick={() => toggleFullSheetsList()}>
                        <h4 style={{fontSize: '24px', fontWeight: '400'}}>Full sheets</h4>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', color: '#3a86ff', cursor: 'pointer'}}>
                            <p>{expandFullSheetsList === true ? 'Collapse' : 'Expand'}</p>
                            <FiChevronUp />
                        </div>
                    </div>
                    {expandFullSheetsList === true ? (
                        <div className='orderSectionContent'>
                            <div className='fullSheetsListRowLabels'>
                                <div className='grid_item'><p>#</p></div>
                                <div className='grid_item'><p>Material decor code / name</p></div>
                                <div className='grid_item'><p>Thick [mm]</p></div>
                                <div className='grid_item'><p>Length [mm]</p></div>
                                <div className='grid_item'><p>Width [mm]</p></div>
                                <div className='grid_item'><p>Qty</p></div>
                                <div className='grid_item'><p>Brand</p></div>
                                <div className='grid_item' style={{ alignItems: 'end', paddingRight: '10px' }}><p>Actions</p></div>
                            </div>
                            {fullSheetsRows.map((row, index) => (
                                <FullSheetRow 
                                    key={index} 
                                    rowID={index} 
                                    row={row} 
                                    openMaterialSelection={openFullSheetMaterialSelectModal}
                                    openRemoveConfirmationPopup={removeFullSheetRow}
                                    fetchRowsConfig={fetchFullSheetsConfig}/>
                            ))}
                            <div className='addRowContainer'>
                                <div className='addRowBtn' onClick={() => addNewFullSheetsRow()}>
                                    <p>Add full sheet row</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>


                {/* EDGE TAPE LIST */}
                {/* POPUPS */}
                {edgingTapeSelectModalVisile === true ? (
                    <FullSheetMaterialSelection closeMaterialSelect={closeFullSheetMaterialSelectModal} />
                ) : null}
                {edgingTapeRemoveConfirmationModalVisible === true ? (
                <EdgetapeRemove closeRemoveConfirmationPopup={closeEdgingTapeRemoveConfirmationModal} removeRow={confirmedEdgingTapeRowRemove}/>
            ) : null}
                <div className='orderSection'>
                    <div className='orderSectionHeader' onClick={() => toggleEdgetapeList()}>
                        <h4 style={{fontSize: '24px', fontWeight: '400'}}>Edging tape</h4>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', color: '#3a86ff', cursor: 'pointer'}}>
                            <p>{expandEdgetapeList === true ? 'Collapse' : 'Expand'}</p>
                            <FiChevronUp />
                        </div>
                    </div>
                    {expandEdgetapeList === true ? (
                        <div className='orderSectionContent'>
                            <div className='edgetapeListRowLabels'>
                                <div className='grid_item'><p>#</p></div>
                                <div className='grid_item'><p>Edging decor code</p></div>
                                <div className='grid_item'><p>Product name - decor name</p></div>
                                <div className='grid_item'><p>Size [mm]</p></div>
                                <div className='grid_item'><p>Qty [m]</p></div>
                                <div className='grid_item' style={{ alignItems: 'end', paddingRight: '10px' }}><p>Actions</p></div>
                            </div>
                            {edgingTapeRows.map((row, index) => (
                                <EdgetapeRow 
                                    key={index} 
                                    rowID={index} 
                                    row={row} 
                                    updateRowConfig={fetchEdgingTapeConfig}
                                    removeRow={removeEdgingTapeRow}
                                    />
                            ))}
                            <div className='addRowContainer'>
                                <div className='addRowBtn' onClick={() => addNewEdgingTapeRow()}>
                                    <p>Add edging tape row</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* Go to the pricing page button */}
            {pricingAvailable === true ? (
                <Link to="/pricing" className='pricingPageBtn'>
                    <p>GET PRICING</p>
                    <FiArrowRight />
                </Link>
            ) : null}
        </div>
    )
}

export default NewOrder