import React, { useState, useEffect, useRef, useContext } from 'react'
import './header.css'
import { Link } from 'react-router-dom'
import { FaCartShopping } from "react-icons/fa6";
import autoAnimate from '@formkit/auto-animate';
import { CartContext } from '../shop/cart/cartProvider';
import { IoMenuOutline, IoCloseOutline, IoCartOutline } from "react-icons/io5";
import { HiOutlineUserCircle } from "react-icons/hi2";

function Header() {
    const { getCartItemCount } = useContext(CartContext);
    const cartItemsCount = getCartItemCount();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () =>{
        setMobileMenuOpen(!mobileMenuOpen);
    }

    const menuBtnListRef = useRef(null);
    useEffect(() => {
        if (menuBtnListRef.current) {
            autoAnimate(menuBtnListRef.current);
        }
    }, []);

    return (
        <div className='headerSection'>
            <div className='headerContainer'>
                <div className='headerMenuMobile'>
                    <div className='headerMenuMobileToggleBtn' ref={menuBtnListRef} onClick={toggleMobileMenu} style={{background: mobileMenuOpen ? '#FF5A2E' : null, color: mobileMenuOpen ? 'white' : null}}>
                        {mobileMenuOpen === true ? <IoCloseOutline className='headerMenuMobileMenuIcon'/> : <IoMenuOutline className='headerMenuMobileMenuIcon'/>}
                    </div>
                    <Link to={'/neworder'} className='headerLogoMobile'>
                        <img src='\res\images\myBoardsLogoShadow.png' alt='Logo'/>
                    </Link>
                </div>
                <div className='headerMenuMobile'>
                    <Link to="/myaccount" className='headerMenuMobileIconBox'>
                        <HiOutlineUserCircle className='headerMenuMobileMenuIcon'/>
                    </Link>
                    {/*<Link to="/cart" className='headerMenuMobileIconBox' style={{display: 'none'}}>
                        <IoCartOutline className='headerMenuMobileMenuIcon'/>
                    </Link>*/}
                </div>
                <Link to={'/neworder'} className='headerLogo'>
                    <img src='\res\images\myBoardsLogoShadow.png' alt='Logo'/>
                </Link>
                <div className='headerMenu'>
                    {/*
                    <Link to="/shop" className='headerMenuItem'> 
                        <p>Shop</p>
                    </Link> */}
                    <Link to="/neworder" className='headerMenuItem'>
                        <p>Cut & Edge</p>
                    </Link>
                    <Link to="/ordershistory" className='headerMenuItem'>
                        <p>Orders</p>
                    </Link>
                    <Link to="/myaccount" className='headerMenuItem'>
                        <p>My Account</p>
                    </Link>
                    {/*
                    <Link to="/cart" className='headerCart'>
                        <FaCartShopping/>
                        {cartItemsCount > 0 ? 
                            <div className='headerCartCount'>
                                <p className='headerCartCount-value'>{cartItemsCount}</p>
                            </div> : null
                        }
                    </Link> */}
                </div>

                {mobileMenuOpen === true ? (
                    <div className='headerMobileDropdownMenu'>
                        {/* 
                        <Link to="/shop" className='headerMobileMenuItem'>
                            <p>Shop</p>
                        </Link> */}
                        <Link to="/neworder" className='headerMobileMenuItem'>
                            <p>Cut & Edge</p>
                        </Link>
                        <Link to="/ordershistory" className='headerMobileMenuItem'>
                            <p>Orders</p>
                        </Link>
                    </div>
                ) : null}

            </div>
        </div>
    )
}

export default Header