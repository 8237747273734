import React, {useState, useEffect} from 'react';
import './App.css';
import NewOrder from './components/new_order/newOrder';
import PricingPage from './components/pricingPage/pricingPage';
import DeliveryInfo from './components/deliveryInfo/deliveryInfo';
import LoginPage from './components/loginPage/loginPage';
import RegisterPage from './components/registerPage/registerPage';
import ForgotPasswordPage from './components/forgotPasswordPage/forgotPasswordPage';
import ResetPasswordPage from './components/resetPasswordPage/resetPasswordPage';
import MyAccount from './components/myAccountPage/myAccount';
import OrdersHistory from './components/ordersHistoryPage/ordersHistory';
import Success from './components/success_checkout/success';
import Comenzi from './components/comenzi/comenzi';
import CereriSup from './components/cereriSup/cereriSup';
import PreturiServ from './components/preturiServ/preturiServ';
import ConturiClienti from './components/conturiClienti/conturiClienti';
import ConturiSmek from './components/conturiSmek/conturiSmek';
import ProduseJmek from './components/produseJmek/produseJmek';
import ShopPage from './components/shop/shopPage';
import Categorii from './components/produseJmek/categorii/categorii';
import AddProduct from './components/produseJmek/addProduct/addProduct';
import ProductPage from './components/shop/productPage/productPage';
import EditProduct from './components/produseJmek/editProduct/editProduct';
import CartPage from './components/shop/cartPage/cartPage';

import PdfPreview from './components/pdfOrderSummary/pdfPreview';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createContext } from 'react';
import { CartProvider } from './components/shop/cart/cartProvider';

import { TbArrowAutofitWidth } from "react-icons/tb";


function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

function useDeviceType() {
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const isTablet = width >= 768 && width <= 800;
  return { isMobile, isTablet };
}

function App() {
  const { isMobile, isTablet } = useDeviceType();

  return (
    <CartProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<LoginPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='register' element={<RegisterPage />} />
            <Route path='forgotpass' element={<ForgotPasswordPage />} />
            <Route path="resetpass/:resetcode" element={<ResetPasswordPage />} />
            <Route path='neworder' element={<NewOrder />} />
            <Route path='pricing' element={<PricingPage />} />
            <Route path='deliveryinfo' element={<DeliveryInfo />} />
            <Route path='myaccount' element={<MyAccount />} />
            <Route path='ordershistory' element={<OrdersHistory />} />
            <Route path="success/:checkout_id" element={<Success />} />
            <Route path="administrator" element={<Comenzi />} />
            <Route path="administrator/cereri-suport" element={<CereriSup />} />
            <Route path="administrator/preturi-servicii" element={<PreturiServ />} />
            <Route path="administrator/conturi-clienti" element={<ConturiClienti />} />
            <Route path="administrator/conturi-admini" element={<ConturiSmek />} />
            <Route path="administrator/produse" element={<ProduseJmek />} />
            <Route path="administrator/categorii" element={<Categorii />} />
            <Route path="administrator/adauga-produs" element={<AddProduct />} />
            <Route path="pdf" element={<PdfPreview />} />
            <Route path="shop" element={<ShopPage />} />
            <Route path="shop/:urlcategory" element={<ShopPage />} />
            <Route path="shop/product/:urlproductid" element={<ProductPage />} />
            <Route path="administrator/editare-produs/:productid" element={<EditProduct />} />
            <Route path="cart" element={<CartPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </CartProvider>
  );
}

export default App;
