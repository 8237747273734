import axios from 'axios';

import { APIurl } from '../../ApiService';


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| CUTTING LIST |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/

// Initial array
let selectedMaterials = [];
let rowsConfig = [];
let rowWithOpenSelection = null;
let thicknessProps = [];
let orderTotalPrice = 0;
let orderTotalPriceVAT = 0;

export const checkSavedOrderConfig = async() =>{
    const existingOrderConfig = localStorage.getItem('orderConfig');
    if(existingOrderConfig !== null){
        const orderConfig = JSON.parse(existingOrderConfig);
        selectedMaterials = orderConfig.selectedMaterials;
        rowsConfig = orderConfig.rowsConfig;
        thicknessProps = orderConfig.thicknessProps;
        fullSheetsSelectedMaterials = orderConfig.fullSheetsSelectedMaterials;
        fullSheetsConfig = orderConfig.fullSheetsConfig;
        fullSheetsThicknessProps = orderConfig.fullSheetsThicknessProps;
        edgingTapeConfig = orderConfig.edgingTapeConfig;
        availableEdgingTape = orderConfig.availableEdgingTape;
        console.log('Order config found in local storage');
    }else{
        console.log('No order config found in local storage');
        return;
    }
}

export const saveOrderConfig = async() =>{
    const orderConfig = {
        selectedMaterials: selectedMaterials,
        rowsConfig: rowsConfig,
        thicknessProps: thicknessProps,
        fullSheetsSelectedMaterials: fullSheetsSelectedMaterials,
        fullSheetsConfig: fullSheetsConfig,
        fullSheetsThicknessProps: fullSheetsThicknessProps,
        edgingTapeConfig: edgingTapeConfig,
        availableEdgingTape: availableEdgingTape,
    }
    localStorage.setItem('orderConfig', JSON.stringify(orderConfig));
}

// Function to return the selected materials array
export const getSelectedMaterials = () => selectedMaterials;

// Function to push to the selected materials array
export const pushToSelectedMaterials = (newMaterial) => {
    // copy the material basic information
    var tempMaterial = {
        manufacturer: newMaterial.manufacturer,
        product_code: newMaterial.product_code,
        color_description: newMaterial.color_description,
        back_side_description: newMaterial.back_side_description,
        image: newMaterial.image_1,
        material_type: newMaterial.material_type,
        cutting: newMaterial.cutting,
        product_characteristics: newMaterial.product_characteristics,
        material_length: newMaterial.material_length,
        width: newMaterial.width,
        grain: newMaterial.grain,
    }

    // look for each thickness and only add the ones that exist
    // For loop to create JSX elements
    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        if (newMaterial[thicknessKey] != null && newMaterial[thicknessKey] != '' && newMaterial[thicknessKey] != 0) {
            tempMaterial[thicknessKey] = newMaterial[thicknessKey];
            tempMaterial[thickness_angle_cutting] = newMaterial[thickness_angle_cutting];
            tempMaterial[thickness_cnc] = newMaterial[thickness_cnc];
            tempMaterial[thickness_cut_price] = newMaterial[thickness_cut_price];
            tempMaterial[thickness_drilling] = newMaterial[thickness_drilling];
            tempMaterial[thickness_edgebanding] = newMaterial[thickness_edgebanding];
            tempMaterial[thickness_edgebanding1_bevel] = newMaterial[thickness_edgebanding1_bevel];
            tempMaterial[thickness_edgebanding1_description] = newMaterial[thickness_edgebanding1_description];
            tempMaterial[thickness_edgebanding1_price] = newMaterial[thickness_edgebanding1_price];
            tempMaterial[thickness_edgebanding1_radius] = newMaterial[thickness_edgebanding1_radius];
            tempMaterial[thickness_edgebanding1_size] = newMaterial[thickness_edgebanding1_size];
            tempMaterial[thickness_edgebanding1_square] = newMaterial[thickness_edgebanding1_square];
            tempMaterial[thickness_edgebanding1_type] = newMaterial[thickness_edgebanding1_type];
            tempMaterial[thickness_edgebanding2_bevel] = newMaterial[thickness_edgebanding2_bevel];
            tempMaterial[thickness_edgebanding2_description] = newMaterial[thickness_edgebanding2_description];
            tempMaterial[thickness_edgebanding2_price] = newMaterial[thickness_edgebanding2_price];
            tempMaterial[thickness_edgebanding2_radius] = newMaterial[thickness_edgebanding2_radius];
            tempMaterial[thickness_edgebanding2_size] = newMaterial[thickness_edgebanding2_size];
            tempMaterial[thickness_edgebanding2_square] = newMaterial[thickness_edgebanding2_square];
            tempMaterial[thickness_edgebanding2_type] = newMaterial[thickness_edgebanding2_type];
            tempMaterial[thickness_edgebanding3_bevel] = newMaterial[thickness_edgebanding3_bevel];
            tempMaterial[thickness_edgebanding3_description] = newMaterial[thickness_edgebanding3_description];
            tempMaterial[thickness_edgebanding3_price] = newMaterial[thickness_edgebanding3_price];
            tempMaterial[thickness_edgebanding3_radius] = newMaterial[thickness_edgebanding3_radius];
            tempMaterial[thickness_edgebanding3_size] = newMaterial[thickness_edgebanding3_size];
            tempMaterial[thickness_edgebanding3_square] = newMaterial[thickness_edgebanding3_square];
            tempMaterial[thickness_edgebanding3_type] = newMaterial[thickness_edgebanding3_type];
            tempMaterial[thickness_glued] = newMaterial[thickness_glued];
            tempMaterial[thickness_hinge_drilling] = newMaterial[thickness_hinge_drilling];
            tempMaterial[thickness_sheet_price] = newMaterial[thickness_sheet_price];
            tempMaterial[thickness_shelf_drilling] = newMaterial[thickness_shelf_drilling];
            tempMaterial[thickness_spray_coating] = newMaterial[thickness_spray_coating];
            tempMaterial[thickness_stock] = newMaterial[thickness_stock];

        }
    }

    selectedMaterials[rowWithOpenSelection] = tempMaterial;
    saveOrderConfig();
};

export const getSelectedMaterial = (index) => {
    const material = selectedMaterials[index];
    return material;
};


// Function to return the rows configuration array
export const getRowsConfig = () => {return rowsConfig};



// Function to push to the rows configuration array
export const addNewRowInConfig = () => {
    if(rowsConfig.length > 0){
        const lastRow = rowsConfig[rowsConfig.length - 1];
        if(lastRow.productCode !== null){
        // copy the material basic information
        var tempConfig = {
            productCode: lastRow.productCode,
            thickness: null,
            quantity: '',
            materialLength: '',
            width: '',
            partDescription: '',
            grainMatch: false,
            edgebandingConfig: null,
            machiningConfig: null,
            sprayConfig: null,
            qtyState: false,
            lengthState: false,
            widthState: false,
            thicknessID: null,
            looks: null,
            brand: null,
            bonded: false,
        }
        rowsConfig = [...rowsConfig, tempConfig];
        const lastMaterial = selectedMaterials[selectedMaterials.length - 1];
        selectedMaterials.push(lastMaterial);
        saveOrderConfig();
        }else{
            var tempConfig = {
                productCode: null,
                thickness: null,
                quantity: '',
                materialLength: '',
                width: '',
                partDescription: '',
                grainMatch: false,
                edgebandingConfig: null,
                machiningConfig: null,
                sprayConfig: null,
                qtyState: false,
                lengthState: false,
                widthState: false,
                thicknessID: null,
                looks: null,
                brand: null,
                bonded: false,
            }
            rowsConfig = [...rowsConfig, tempConfig];
        }
    }else{
        var tempConfig = {
            productCode: null,
            thickness: null,
            quantity: '',
            materialLength: '',
            width: '',
            partDescription: '',
            grainMatch: false,
            edgebandingConfig: null,
            machiningConfig: null,
            sprayConfig: null,
            qtyState: false,
            lengthState: false,
            widthState: false,
            thicknessID: null,
            looks: null,
            brand: null,
            bonded: false,
        }
        rowsConfig = [...rowsConfig, tempConfig];
        saveOrderConfig();
    }
}

// Function to change the selected material for a row from the material selection modal, it also sets all the other inputs to null
export const selectedMaterialForRow = (productCode) => {
    console.log('new material selected for row:' + rowWithOpenSelection + ', ' + productCode);
    var tempConfig = {
        productCode: productCode,
        thickness: null,
        quantity: '',
        materialLength: '',
        width: '',
        partDescription: '',
        grainMatch: false,
        edgebandingConfig: null,
        machiningConfig: null,
        sprayConfig: null,
        qtyState: false,
        lengthState: false,
        widthState: false,
        thicknessID: null,
        looks: null,
        brand: null,
        bonded: false,
    }

    rowsConfig[rowWithOpenSelection] = tempConfig;
};

export const getRowConfig = (row) => {
    const rowConfig = rowsConfig[row];

    return rowConfig;
};

// Function to store the current row with an open modal
export const changeRowWithOpenSelection = (rowID) => {
    rowWithOpenSelection = rowID;
};

// Function to store the current row with an open modal
export const getRowWithOpenSelection = () => {
    return rowWithOpenSelection;
};

// Function to delete a row from the order
export const removeRow = () => {
    if (rowsConfig !== -1) {
        // Use filter to create a new array without the specified element
        rowsConfig = rowsConfig.filter((_, index) => index !== rowWithOpenSelection);
    }
    if (thicknessProps !== -1) {
        // Use filter to create a new array without the specified element
        thicknessProps = thicknessProps.filter((_, index) => index !== rowWithOpenSelection);
    }
    saveOrderConfig();
};


// Function to return the available thicknesses for a material
export const getAvailableThicknesses = () => {
    var availableThicknesses = [];
    const material = selectedMaterials[rowWithOpenSelection];

    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        if (material[thicknessKey] != null && material[thicknessKey] != '' && material[thicknessKey] != 0) {
            availableThicknesses = [...availableThicknesses, material[thicknessKey]]
        }
    }

    saveOrderConfig();

    return availableThicknesses;
};

// Function to set the selected thickness for a row
export const selectedThicknessForRow = (thickness) => {
    const currentConfig = rowsConfig[rowWithOpenSelection];
    var tempConfig = {
        productCode: currentConfig.productCode,
        thickness: thickness,
        quantity: '',
        materialLength: '',
        width: '',
        partDescription: '',
        grainMatch: false,
        edgebandingConfig: null,
        machiningConfig: null,
        sprayConfig: null,
        qtyState: false,
        lengthState: false,
        widthState: false,
        thicknessID: currentConfig.thicknessID,
        looks: currentConfig.looks,
        brand: currentConfig.brand,
        bonded: currentConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = tempConfig;
    updateThicknessProps(thickness);
    saveOrderConfig();
};

const updateThicknessProps = (thickness) => {
    var tempThickProps = {
        thickness : null,
        angle_cutting : null,
        cnc : null,
        cut_price : null,
        drilling : null,
        edgebanding : null,
        edgebanding1_bevel : null,
        edgebanding1_description : null,
        edgebanding1_price : null,
        edgebanding1_radius : null,
        edgebanding1_size : null,
        edgebanding1_square : null,
        edgebanding1_type : null,
        edgebanding2_bevel : null,
        edgebanding2_description : null,
        edgebanding2_price : null,
        edgebanding2_radius : null,
        edgebanding2_size : null,
        edgebanding2_square : null,
        edgebanding2_type : null,
        edgebanding3_bevel : null,
        edgebanding3_description : null,
        edgebanding3_price : null,
        edgebanding3_radius : null,
        edgebanding3_size : null,
        edgebanding3_square : null,
        edgebanding3_type : null,
        glued : null,
        hinge_drilling : null,
        sheet_price : null,
        shelf_drilling : null,
        spray_coating : null,
        stock : null,
        thicknessId: null,
    }

    const materialSelected = selectedMaterials[rowWithOpenSelection];

    for(let i=1; i<=11; i++){
        // kes to get the properties out of the materialSelected array
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        const iterationThick = materialSelected[thicknessKey];
        if((iterationThick == thickness) && (materialSelected[thicknessKey] != null && materialSelected[thicknessKey] != '' && materialSelected[thicknessKey] != 0)){
            tempThickProps.thickness = materialSelected[thicknessKey];
            tempThickProps.angle_cutting = materialSelected[thickness_angle_cutting];
            tempThickProps.cnc = materialSelected[thickness_cnc];
            tempThickProps.cut_price = materialSelected[thickness_cut_price];
            tempThickProps.drilling = materialSelected[thickness_drilling];
            tempThickProps.edgebanding = materialSelected[thickness_edgebanding];
            tempThickProps.edgebanding1_bevel = materialSelected[thickness_edgebanding1_bevel];
            tempThickProps.edgebanding1_description = materialSelected[thickness_edgebanding1_description];
            tempThickProps.edgebanding1_price = materialSelected[thickness_edgebanding1_price];
            tempThickProps.edgebanding1_radius = materialSelected[thickness_edgebanding1_radius];
            tempThickProps.edgebanding1_size = materialSelected[thickness_edgebanding1_size];
            tempThickProps.edgebanding1_square = materialSelected[thickness_edgebanding1_square];
            tempThickProps.edgebanding1_type = materialSelected[thickness_edgebanding1_type];
            tempThickProps.edgebanding2_bevel = materialSelected[thickness_edgebanding2_bevel];
            tempThickProps.edgebanding2_description = materialSelected[thickness_edgebanding2_description];
            tempThickProps.edgebanding2_price = materialSelected[thickness_edgebanding2_price];
            tempThickProps.edgebanding2_radius = materialSelected[thickness_edgebanding2_radius];
            tempThickProps.edgebanding2_size = materialSelected[thickness_edgebanding2_size];
            tempThickProps.edgebanding2_square = materialSelected[thickness_edgebanding2_square];
            tempThickProps.edgebanding2_type = materialSelected[thickness_edgebanding2_type];
            tempThickProps.edgebanding3_bevel = materialSelected[thickness_edgebanding3_bevel];
            tempThickProps.edgebanding3_description = materialSelected[thickness_edgebanding3_description];
            tempThickProps.edgebanding3_price = materialSelected[thickness_edgebanding3_price];
            tempThickProps.edgebanding3_radius = materialSelected[thickness_edgebanding3_radius];
            tempThickProps.edgebanding3_size = materialSelected[thickness_edgebanding3_size];
            tempThickProps.edgebanding3_square = materialSelected[thickness_edgebanding3_square];
            tempThickProps.edgebanding3_type = materialSelected[thickness_edgebanding3_type];
            tempThickProps.glued = materialSelected[thickness_glued];
            tempThickProps.hinge_drilling = materialSelected[thickness_hinge_drilling];
            tempThickProps.sheet_price = materialSelected[thickness_sheet_price];
            tempThickProps.shelf_drilling = materialSelected[thickness_shelf_drilling];
            tempThickProps.spray_coating = materialSelected[thickness_spray_coating];
            tempThickProps.stock = materialSelected[thickness_stock];
            tempThickProps.thicknessId = parseInt(i);
        }
    }
    thicknessProps[rowWithOpenSelection] = tempThickProps;
    rowsConfig[rowWithOpenSelection].thicknessID = tempThickProps.thicknessId;
    rowsConfig[rowWithOpenSelection].looks = selectedMaterials[rowWithOpenSelection].color_description;
    rowsConfig[rowWithOpenSelection].brand = selectedMaterials[rowWithOpenSelection].manufacturer;
    rowsConfig[rowWithOpenSelection].bonded = tempThickProps.glued.toLowerCase() === 'yes' ? true : false;
    console.log('//// -> thickProps for the new thickness selection:');
    console.log(tempThickProps);
    saveOrderConfig();
}

export const getThickProps = (index) => {
    const rowThickProps = thicknessProps[index];
    return rowThickProps;
    saveOrderConfig();
}

export const getThickPropsEdgebanding = () => {
    const rowThickProps = thicknessProps[rowWithOpenSelection];
    saveOrderConfig();
    return rowThickProps;
}

// Function to update the row config
export const changeRowConfig = (row, prop, value) => {
    if (prop === 'qty') {
        rowsConfig[row].quantity = value;
        saveOrderConfig();
        return;
    } else if (prop === 'length') {
        rowsConfig[row].materialLength = value;
        saveOrderConfig();
        return;
    } else if (prop === 'width') {
        rowsConfig[row].width = value;
        saveOrderConfig();
        return;
    } else if (prop === 'partDescription') {
        rowsConfig[row].partDescription = value;
        saveOrderConfig();
        return;
    } else if (prop === 'grainMatch') {
        rowsConfig[row].grainMatch = value;
        saveOrderConfig();
        return;
    }
};

export const updateEdgebandingConfig = (edgebandingConf) => {
    const currRowConfig = rowsConfig[rowWithOpenSelection];
    const newRowConfig = {
        productCode: currRowConfig.productCode,
        thickness: currRowConfig.thickness,
        quantity: currRowConfig.quantity,
        materialLength: currRowConfig.materialLength,
        width: currRowConfig.width,
        partDescription: currRowConfig.partDescription,
        grainMatch: currRowConfig.grainMatch,
        edgebandingConfig: edgebandingConf,
        machiningConfig: currRowConfig.machiningConfig,
        sprayConfig: currRowConfig.sprayConfig,
        qtyState: currRowConfig.qtyState,
        lengthState: currRowConfig.lengthState,
        widthState: currRowConfig.widthState,
        thicknessID: currRowConfig.thicknessID,
        looks: currRowConfig.looks,
        brand: currRowConfig.brand,
        bonded: currRowConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = newRowConfig;

    //  look for edgebanding to add to the available edging tape array
    if(edgebandingConf.l1 == true || edgebandingConf.l2 == true || edgebandingConf.w1 == true || edgebandingConf.w2 == true){
        if(edgebandingConf.l1){
            const edgebandingObj = edgebandingConf.l1_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
        if(edgebandingConf.l2){
            const edgebandingObj = edgebandingConf.l2_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
        if(edgebandingConf.w1){
            const edgebandingObj = edgebandingConf.w1_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
        if(edgebandingConf.w2){
            const edgebandingObj = edgebandingConf.w2_obj;
            const edgingTapeOption = {
                name: edgebandingObj.edgebanding_type,
                description: edgebandingObj.edgebanding_description,
                size: edgebandingObj.edgebanding_size,
                decor_name: currRowConfig.productCode,
            }
            // Find the MDF panel with the same product code and thickness
            let existingEdgingTapeInArray = availableEdgingTape.find(edgingTape => edgingTape.decor_name == edgingTapeOption.decor_name && edgingTape.name == edgingTapeOption.name);
            if(existingEdgingTapeInArray){
                //  don't add
            }else{
                availableEdgingTape = [...availableEdgingTape, edgingTapeOption];
                console.log('ADDED EDGING TAPE TO THE AVAILABLE LIST');
            }
        }
    }
    saveOrderConfig();
};

export const getEdgebandingOptions = () => {
    var edgebandingOptions = [];

    const currMaterial = thicknessProps[rowWithOpenSelection];
    console.log(currMaterial);
    if(currMaterial.edgebanding1_price != null && currMaterial.edgebanding1_price != ''){
        console.log('found option 1');
        let edgeOption = {
            edgebanding_bevel: currMaterial.edgebanding1_bevel, 
            edgebanding_description: currMaterial.edgebanding1_description,
            edgebanding_price: currMaterial.edgebanding1_price,
            edgebanding_radius: currMaterial.edgebanding1_radius,
            edgebanding_size: currMaterial.edgebanding1_size,
            edgebanding_square: currMaterial.edgebanding1_square,
            edgebanding_type: currMaterial.edgebanding1_type
        }
        edgebandingOptions = [...edgebandingOptions, edgeOption];
    }
    if(currMaterial.edgebanding2_price != null && currMaterial.edgebanding2_price != ''){
        console.log('found option 1');
        let edgeOption = {
            edgebanding_bevel: currMaterial.edgebanding2_bevel, 
            edgebanding_description: currMaterial.edgebanding2_description,
            edgebanding_price: currMaterial.edgebanding2_price,
            edgebanding_radius: currMaterial.edgebanding2_radius,
            edgebanding_size: currMaterial.edgebanding2_size,
            edgebanding_square: currMaterial.edgebanding2_square,
            edgebanding_type: currMaterial.edgebanding2_type
        }
        edgebandingOptions = [...edgebandingOptions, edgeOption];
    }
    if(currMaterial.edgebanding3_price != null && currMaterial.edgebanding3_price != ''){
        console.log('found option 1');
        let edgeOption = {
            edgebanding_bevel: currMaterial.edgebanding3_bevel, 
            edgebanding_description: currMaterial.edgebanding3_description,
            edgebanding_price: currMaterial.edgebanding3_price,
            edgebanding_radius: currMaterial.edgebanding3_radius,
            edgebanding_size: currMaterial.edgebanding3_size,
            edgebanding_square: currMaterial.edgebanding3_square,
            edgebanding_type: currMaterial.edgebanding3_type
        }
        edgebandingOptions = [...edgebandingOptions, edgeOption];
    }

    console.log(edgebandingOptions);
    return edgebandingOptions;
}

// save the changes made to machining config for row
export const updateMachiningConfig = (newMachiningConf, newDrawingProps) => {
    const currRowConfig = rowsConfig[rowWithOpenSelection];
    const newRowConfig = {
        productCode: currRowConfig.productCode,
        thickness: currRowConfig.thickness,
        quantity: currRowConfig.quantity,
        materialLength: currRowConfig.materialLength,
        width: currRowConfig.width,
        partDescription: currRowConfig.partDescription,
        grainMatch: currRowConfig.grainMatch,
        edgebandingConfig: currRowConfig.edgebandingConfig,
        machiningConfig: newMachiningConf,
        sprayConfig: currRowConfig.sprayConfig,
        qtyState: currRowConfig.qtyState,
        lengthState: currRowConfig.lengthState,
        widthState: currRowConfig.widthState,
        thicknessID: currRowConfig.thicknessID,
        looks: currRowConfig.looks,
        brand: currRowConfig.brand,
        bonded: currRowConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = newRowConfig;
    saveOrderConfig();
};

// save the changes made to machining config for row
export const updateSprayConfig = (newSprayConfig) => {
    const currRowConfig = rowsConfig[rowWithOpenSelection];
    const newRowConfig = {
        productCode: currRowConfig.productCode,
        thickness: currRowConfig.thickness,
        quantity: currRowConfig.quantity,
        materialLength: currRowConfig.materialLength,
        width: currRowConfig.width,
        partDescription: currRowConfig.partDescription,
        grainMatch: currRowConfig.grainMatch,
        edgebandingConfig: currRowConfig.edgebandingConfig,
        machiningConfig: currRowConfig.machiningConfig,
        sprayConfig: newSprayConfig,
        qtyState: currRowConfig.qtyState,
        lengthState: currRowConfig.lengthState,
        widthState: currRowConfig.widthState,
        thicknessID: currRowConfig.thicknessID,
        looks: currRowConfig.looks,
        brand: currRowConfig.brand,
        bonded: currRowConfig.bonded,
    }
    rowsConfig[rowWithOpenSelection] = newRowConfig;
    saveOrderConfig();
};



let remainingCuts = [];
let bins = [];
let mdfPanelsInOrder = [];
//  group cuts based on product code and thickness
const groupCuts = () =>{
    for (let i = 0; i < rowsConfig.length; i++) {
        if(rowsConfig[i].productCode != null && rowsConfig[i].thickness != null && rowsConfig[i].quantity != null && rowsConfig[i].materialLength != null && rowsConfig[i].width != null){
            console.log('-> found a valid row in the order');
            const cut = {
                product_code: rowsConfig[i].productCode,
                thickness: rowsConfig[i].thickness,
                length: parseInt(rowsConfig[i].materialLength),
                width: parseInt(rowsConfig[i].width),
                quantity: parseInt(rowsConfig[i].quantity),
                placed: false,
                grain_match: rowsConfig[i].grainMatch,
            };
            console.log(`cut ${i} length:` + cut.length);
            console.log(`cut ${i} grain_match:` + cut.grain_match);
    
            // Find the MDF panel with the same product code and thickness
            let mdfPanel = mdfPanelsInOrder.find(panel => panel.product_code === cut.product_code && panel.thickness === cut.thickness);
            console.log('mdfPanel: ' + mdfPanel);

            if (!mdfPanel) {
                // If MDF panel doesn't exist, create a new one
                console.log('thicknessId for mdPanel:' +i, thicknessProps[i].thicknessId);
                console.log(thicknessProps);
                mdfPanel = {
                    product_code: cut.product_code,
                    thickness: cut.thickness,
                    thicknessID: thicknessProps[i].thicknessId,
                    decor_name: selectedMaterials[i].color_description,
                    material_type: selectedMaterials[i].material_type,
                    brand: selectedMaterials[i].manufacturer,
                    cuts: []
                };
                // Add cuts based on the quantity
                for (let j = 0; j < parseInt(rowsConfig[i].quantity); j++) {
                    console.log('adding cut to mdfPanel');
                    mdfPanel.cuts.push({
                        length: cut.length,
                        width: cut.width,
                        placed: cut.placed,
                        grain_match: cut.grain_match,
                    });
                }
                mdfPanelsInOrder.push(mdfPanel);
            }else{
                for (let j = 0; j < parseInt(rowsConfig[i].quantity); j++) {
                    mdfPanel.cuts.push({
                        length: cut.length,
                        width: cut.width,
                        placed: cut.placed,
                        grain_match: cut.grain_match,
                    });
                }
            }
        }
    }
    console.log(mdfPanelsInOrder);

}
let newBins = [];
const newRemainingCuts = [];
let newOffcutsArray = [];
let currentBinSelected = null;
let placedCutsArray = [];
export const getPlacedCutsArray = () =>{
    return placedCutsArray;
}
export const getPlacedCutsArrayAsync = () =>{
    return placedCutsArray;
}
export const getOffcutsArrayAsync = () =>{
    return newOffcutsArray;
}
let offcutsArray = [];
export const getBins = async() =>{
    return newBins;
}

//  match the bins with the selectedMaterials and return their details
export const getBinDetails = () =>{
    let detailsArray = []
    for (let i = 0; i < newBins.length; i++) {
        const bin = newBins[i];
        for(let j = 0; j < selectedMaterials.length; j++){
            if(bin.product_code == selectedMaterials[j].product_code){
                console.log('Bin details thickness:' + bin.thickness);
                const detailsObj = {
                    product_code: selectedMaterials[j].product_code,
                    materialLength: selectedMaterials[j].material_length,
                    width: selectedMaterials[j].width,
                    thickness: bin.thickness,
                    grain: selectedMaterials[j].grain,
                    colorDescription: selectedMaterials[j].color_description
                }
                detailsArray = [...detailsArray, detailsObj];
                break;
            }
        }
    }
    return detailsArray;
}

//  set the bin index of the latest bin select offcuts
let binWithOpenSelectOffcuts = null;
export const setBinWithOpenSelectOffcuts = (binId) => {
    binWithOpenSelectOffcuts = binId;
};

//  get the bin placement for the bin with select offcuts modal open
export const getBinPlacementsForBinWithOpenSelectOffcuts = () =>{
    return newBins[binWithOpenSelectOffcuts];
};

//  get the placed cuts for the bin with select offcuts modal open
export const getPlacedCutsForBinWithOpenSelectOffcuts = () =>{
    return placedCutsArray[binWithOpenSelectOffcuts];
};

//  get the offcuts for the bin with select offcuts modal open
export const getOffcutsForBinWithOpenSelectOffcuts = () =>{
    return newOffcutsArray[binWithOpenSelectOffcuts];
};

//  array that stores the offcuts selections offcutsSelections[binIndex].offcuts[offcutIndex]
let offcutsSelections = [];

//  get the offcuts selections for the bin with select offcuts modal open
export const getOffcutsSelectionsForBin = () =>{
    return newOffcutsArray[binWithOpenSelectOffcuts];
};

export const updateOffcutsSelectionsForBin = (newOffcutsSelections) =>{
    newOffcutsArray[binWithOpenSelectOffcuts].offcuts = newOffcutsSelections;
    console.log('OFFCUTS SELECTIONS UPDATED IN ORDER CONFIG:');
    console.log(newOffcutsArray);
}

export const getOffcutsSelections = () =>{
    return newOffcutsArray;
}


//  update the row inputs state to determine if the user can advance to the pricing page
export const changeGoodInputState = (index, inputField, newState) =>{
    const currRowConfig = rowsConfig[index];
    if(inputField == 'qty'){
        const newRowConfig = {
            productCode: currRowConfig.productCode,
            thickness: currRowConfig.thickness,
            quantity: currRowConfig.quantity,
            materialLength: currRowConfig.materialLength,
            width: currRowConfig.width,
            partDescription: currRowConfig.partDescription,
            grainMatch: currRowConfig.grainMatch,
            edgebandingConfig: currRowConfig.edgebandingConfig,
            machiningConfig: currRowConfig.machiningConfig,
            sprayConfig: currRowConfig.sprayConfig,
            qtyState: newState,
            lengthState: currRowConfig.lengthState,
            widthState: currRowConfig.widthState,
            thicknessID: currRowConfig.thicknessID,
            looks: currRowConfig.looks,
            brand: currRowConfig.brand,
            bonded: currRowConfig.bonded,
        }
        rowsConfig[index] = newRowConfig;
    }else if(inputField == 'length'){
        const newRowConfig = {
            productCode: currRowConfig.productCode,
            thickness: currRowConfig.thickness,
            quantity: currRowConfig.quantity,
            materialLength: currRowConfig.materialLength,
            width: currRowConfig.width,
            partDescription: currRowConfig.partDescription,
            grainMatch: currRowConfig.grainMatch,
            edgebandingConfig: currRowConfig.edgebandingConfig,
            machiningConfig: null,
            sprayConfig: currRowConfig.sprayConfig,
            qtyState: currRowConfig.qtyState,
            lengthState: newState,
            widthState: currRowConfig.widthState,
            thicknessID: currRowConfig.thicknessID,
            looks: currRowConfig.looks,
            brand: currRowConfig.brand,
            bonded: currRowConfig.bonded,
        }
        rowsConfig[index] = newRowConfig;
    }else if(inputField == 'width'){
        const newRowConfig = {
            productCode: currRowConfig.productCode,
            thickness: currRowConfig.thickness,
            quantity: currRowConfig.quantity,
            materialLength: currRowConfig.materialLength,
            width: currRowConfig.width,
            partDescription: currRowConfig.partDescription,
            grainMatch: currRowConfig.grainMatch,
            edgebandingConfig: currRowConfig.edgebandingConfig,
            machiningConfig: null,
            sprayConfig: currRowConfig.sprayConfig,
            qtyState: currRowConfig.qtyState,
            lengthState: currRowConfig.lengthState,
            widthState: newState,
            thicknessID: currRowConfig.thicknessID,
            looks: currRowConfig.looks,
            brand: currRowConfig.brand,
            bonded: currRowConfig.bonded,
        }
        rowsConfig[index] = newRowConfig;
    }
    saveOrderConfig();
}



// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| FULL SHEETS |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/

//  full sheets config section
let fullSheetsSelectedMaterials = [];
let fullSheetsConfig = [];
let fullSheetsThicknessProps = [];

let fullSheetsRowWithOpenSelection = null;
export const changeFullSheetRowWithOpenSelection = async(rowID) => {
    console.log('full sheets open row:' + rowID);
    fullSheetsRowWithOpenSelection = rowID;
}

// Function to push to the selected materials array
export const pushToSelectedFullSheetsMaterials = (newMaterial) => {
    // copy the material basic information
    var tempMaterial = {
        manufacturer: newMaterial.manufacturer,
        product_code: newMaterial.product_code,
        color_description: newMaterial.color_description,
        back_side_description: newMaterial.back_side_description,
        image: newMaterial.image_1,
        material_type: newMaterial.material_type,
        cutting: newMaterial.cutting,
        product_characteristics: newMaterial.product_characteristics,
        material_length: newMaterial.material_length,
        width: newMaterial.width,
        grain: newMaterial.grain,
    }

    // look for each thickness and only add the ones that exist
    // For loop to create JSX elements
    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        if (newMaterial[thicknessKey] != null && newMaterial[thicknessKey] != '' && newMaterial[thicknessKey] != 0) {
            tempMaterial[thicknessKey] = newMaterial[thicknessKey];
            tempMaterial[thickness_angle_cutting] = newMaterial[thickness_angle_cutting];
            tempMaterial[thickness_cnc] = newMaterial[thickness_cnc];
            tempMaterial[thickness_cut_price] = newMaterial[thickness_cut_price];
            tempMaterial[thickness_drilling] = newMaterial[thickness_drilling];
            tempMaterial[thickness_edgebanding] = newMaterial[thickness_edgebanding];
            tempMaterial[thickness_edgebanding1_bevel] = newMaterial[thickness_edgebanding1_bevel];
            tempMaterial[thickness_edgebanding1_description] = newMaterial[thickness_edgebanding1_description];
            tempMaterial[thickness_edgebanding1_price] = newMaterial[thickness_edgebanding1_price];
            tempMaterial[thickness_edgebanding1_radius] = newMaterial[thickness_edgebanding1_radius];
            tempMaterial[thickness_edgebanding1_size] = newMaterial[thickness_edgebanding1_size];
            tempMaterial[thickness_edgebanding1_square] = newMaterial[thickness_edgebanding1_square];
            tempMaterial[thickness_edgebanding1_type] = newMaterial[thickness_edgebanding1_type];
            tempMaterial[thickness_edgebanding2_bevel] = newMaterial[thickness_edgebanding2_bevel];
            tempMaterial[thickness_edgebanding2_description] = newMaterial[thickness_edgebanding2_description];
            tempMaterial[thickness_edgebanding2_price] = newMaterial[thickness_edgebanding2_price];
            tempMaterial[thickness_edgebanding2_radius] = newMaterial[thickness_edgebanding2_radius];
            tempMaterial[thickness_edgebanding2_size] = newMaterial[thickness_edgebanding2_size];
            tempMaterial[thickness_edgebanding2_square] = newMaterial[thickness_edgebanding2_square];
            tempMaterial[thickness_edgebanding2_type] = newMaterial[thickness_edgebanding2_type];
            tempMaterial[thickness_edgebanding3_bevel] = newMaterial[thickness_edgebanding3_bevel];
            tempMaterial[thickness_edgebanding3_description] = newMaterial[thickness_edgebanding3_description];
            tempMaterial[thickness_edgebanding3_price] = newMaterial[thickness_edgebanding3_price];
            tempMaterial[thickness_edgebanding3_radius] = newMaterial[thickness_edgebanding3_radius];
            tempMaterial[thickness_edgebanding3_size] = newMaterial[thickness_edgebanding3_size];
            tempMaterial[thickness_edgebanding3_square] = newMaterial[thickness_edgebanding3_square];
            tempMaterial[thickness_edgebanding3_type] = newMaterial[thickness_edgebanding3_type];
            tempMaterial[thickness_glued] = newMaterial[thickness_glued];
            tempMaterial[thickness_hinge_drilling] = newMaterial[thickness_hinge_drilling];
            tempMaterial[thickness_sheet_price] = newMaterial[thickness_sheet_price];
            tempMaterial[thickness_shelf_drilling] = newMaterial[thickness_shelf_drilling];
            tempMaterial[thickness_spray_coating] = newMaterial[thickness_spray_coating];
            tempMaterial[thickness_stock] = newMaterial[thickness_stock];

        }
    }

    fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection] = tempMaterial;
    saveOrderConfig();
};

// Function to change the selected material for a row from the material selection modal, it also sets all the other inputs to null
export const selectedFullSheetsMaterialForRow = (productCode) => {
    console.log('new material selected for full sheets row:' + fullSheetsRowWithOpenSelection + ', ' + productCode);
    var tempConfig = {
        productCode: productCode,
        thickness: null,
        brand: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].manufacturer,
        material_type: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_type,
        decor_name: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].color_description,
        materialLength: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_length,
        width: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].width,
        quantity: null,
        qtyState: false,
        thicknessID: null,
    }

    fullSheetsConfig[fullSheetsRowWithOpenSelection] = tempConfig;
    saveOrderConfig();
};

export const getFullSheetsSelectedMaterials = () => {
    return fullSheetsSelectedMaterials;
};

export const getFullSheetsConfig = () =>{
    return fullSheetsConfig;
}

// Function to push to the full sheets rows configuration array
export const addNewFullSheetsRowInConfig = () => {
    // copy the material basic information
    var tempConfig = {
        productCode: null,
        thickness: null,
        brand: null,
        material_type: null,
        decor_name: null,
        materialLength: null,
        width: null,
        quantity: null,
        qtyState: false,
        thicknessID: null,
    }

    fullSheetsConfig = [...fullSheetsConfig, tempConfig];
    saveOrderConfig();
}

export const getFullSheetsRowWithOpenSelection = () =>{
    return fullSheetsRowWithOpenSelection;
}

// Function to return the available thicknesses for a full sheet material
export const getFullSheetAvailableThicknesses = () => {
    var availableThicknesses = [];
    const material = fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection];

    for (let i = 1; i <= 11; i++) {
        const thicknessKey = `thickness${i}`;
        if (material[thicknessKey] != null && material[thicknessKey] != '' && material[thicknessKey] != 0) {
            availableThicknesses = [...availableThicknesses, material[thicknessKey]]
        }
    }
    return availableThicknesses;
    saveOrderConfig();
};

// Function to set the selected thickness for a row
export const selectedThicknessForFullSheetRow = (thickness) => {
    const currentConfig = fullSheetsConfig[fullSheetsRowWithOpenSelection];
    var tempConfig = {
        productCode: currentConfig.productCode,
        thickness: thickness,
        brand: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].manufacturer,
        material_type: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_type,
        decor_name: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].color_description,
        materialLength: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].material_length,
        width: fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection].width,
        quantity: null,
        qtyState: false,
        thicknessID: currentConfig.thicknessID,
    }
    fullSheetsConfig[fullSheetsRowWithOpenSelection] = tempConfig;
    console.log('New thickness for full sheet:');
    console.log(thickness);
    updateFullSheetsThicknessProps(thickness);
    saveOrderConfig();
};


const updateFullSheetsThicknessProps = (thickness) => {
    var tempThickProps = {
        thickness : null,
        angle_cutting : null,
        cnc : null,
        cut_price : null,
        drilling : null,
        edgebanding : null,
        edgebanding1_bevel : null,
        edgebanding1_description : null,
        edgebanding1_price : null,
        edgebanding1_radius : null,
        edgebanding1_size : null,
        edgebanding1_square : null,
        edgebanding1_type : null,
        edgebanding2_bevel : null,
        edgebanding2_description : null,
        edgebanding2_price : null,
        edgebanding2_radius : null,
        edgebanding2_size : null,
        edgebanding2_square : null,
        edgebanding2_type : null,
        edgebanding3_bevel : null,
        edgebanding3_description : null,
        edgebanding3_price : null,
        edgebanding3_radius : null,
        edgebanding3_size : null,
        edgebanding3_square : null,
        edgebanding3_type : null,
        glued : null,
        hinge_drilling : null,
        sheet_price : null,
        shelf_drilling : null,
        spray_coating : null,
        stock : null,
        thicknessId: null,
    }

    const materialSelected = fullSheetsSelectedMaterials[fullSheetsRowWithOpenSelection];
    console.log(materialSelected);
    
    for(let i=1; i<=11; i++){
        // kes to get the properties out of the materialSelected array
        const thicknessKey = `thickness${i}`;
        const thickness_angle_cutting = `thickness${i}_angle_cutting`;
        const thickness_cnc = `thickness${i}_cnc`;
        const thickness_cut_price = `thickness${i}_cut_price`;
        const thickness_drilling = `thickness${i}_drilling`;
        const thickness_edgebanding = `thickness${i}_edgebanding`;
        const thickness_edgebanding1_bevel = `thickness${i}_edgebanding1_bevel`;
        const thickness_edgebanding1_description = `thickness${i}_edgebanding1_description`;
        const thickness_edgebanding1_price = `thickness${i}_edgebanding1_price`;
        const thickness_edgebanding1_radius = `thickness${i}_edgebanding1_radius`;
        const thickness_edgebanding1_size = `thickness${i}_edgebanding1_size`;
        const thickness_edgebanding1_square = `thickness${i}_edgebanding1_square`;
        const thickness_edgebanding1_type = `thickness${i}_edgebanding1_type`;
        const thickness_edgebanding2_bevel = `thickness${i}_edgebanding2_bevel`;
        const thickness_edgebanding2_description = `thickness${i}_edgebanding2_description`;
        const thickness_edgebanding2_price = `thickness${i}_edgebanding2_price`;
        const thickness_edgebanding2_radius = `thickness${i}_edgebanding2_radius`;
        const thickness_edgebanding2_size = `thickness${i}_edgebanding2_size`;
        const thickness_edgebanding2_square = `thickness${i}_edgebanding2_square`;
        const thickness_edgebanding2_type = `thickness${i}_edgebanding2_type`;
        const thickness_edgebanding3_bevel = `thickness${i}_edgebanding3_bevel`;
        const thickness_edgebanding3_description = `thickness${i}_edgebanding3_description`;
        const thickness_edgebanding3_price = `thickness${i}_edgebanding3_price`;
        const thickness_edgebanding3_radius = `thickness${i}_edgebanding3_radius`;
        const thickness_edgebanding3_size = `thickness${i}_edgebanding3_size`;
        const thickness_edgebanding3_square = `thickness${i}_edgebanding3_square`;
        const thickness_edgebanding3_type = `thickness${i}_edgebanding3_type`;
        const thickness_glued = `thickness${i}_glued`;
        const thickness_hinge_drilling = `thickness${i}_hinge_drilling`;
        const thickness_sheet_price = `thickness${i}_sheet_price`;
        const thickness_shelf_drilling = `thickness${i}_shelf_drilling`;
        const thickness_spray_coating = `thickness${i}_spray_coating`;
        const thickness_stock = `thickness${i}_stock`;

        const iterationThick = materialSelected[thicknessKey];
        if(iterationThick == thickness && materialSelected[thicknessKey] != '' && materialSelected[thicknessKey] != 0){
            tempThickProps.thickness = fullSheetsSelectedMaterials[thicknessKey];
            tempThickProps.angle_cutting = fullSheetsSelectedMaterials[thickness_angle_cutting];
            tempThickProps.cnc = fullSheetsSelectedMaterials[thickness_cnc];
            tempThickProps.cut_price = fullSheetsSelectedMaterials[thickness_cut_price];
            tempThickProps.drilling = fullSheetsSelectedMaterials[thickness_drilling];
            tempThickProps.edgebanding = fullSheetsSelectedMaterials[thickness_edgebanding];
            tempThickProps.edgebanding1_bevel = fullSheetsSelectedMaterials[thickness_edgebanding1_bevel];
            tempThickProps.edgebanding1_description = fullSheetsSelectedMaterials[thickness_edgebanding1_description];
            tempThickProps.edgebanding1_price = fullSheetsSelectedMaterials[thickness_edgebanding1_price];
            tempThickProps.edgebanding1_radius = fullSheetsSelectedMaterials[thickness_edgebanding1_radius];
            tempThickProps.edgebanding1_size = fullSheetsSelectedMaterials[thickness_edgebanding1_size];
            tempThickProps.edgebanding1_square = fullSheetsSelectedMaterials[thickness_edgebanding1_square];
            tempThickProps.edgebanding1_type = fullSheetsSelectedMaterials[thickness_edgebanding1_type];
            tempThickProps.edgebanding2_bevel = fullSheetsSelectedMaterials[thickness_edgebanding2_bevel];
            tempThickProps.edgebanding2_description = fullSheetsSelectedMaterials[thickness_edgebanding2_description];
            tempThickProps.edgebanding2_price = fullSheetsSelectedMaterials[thickness_edgebanding2_price];
            tempThickProps.edgebanding2_radius = fullSheetsSelectedMaterials[thickness_edgebanding2_radius];
            tempThickProps.edgebanding2_size = fullSheetsSelectedMaterials[thickness_edgebanding2_size];
            tempThickProps.edgebanding2_square = fullSheetsSelectedMaterials[thickness_edgebanding2_square];
            tempThickProps.edgebanding2_type = fullSheetsSelectedMaterials[thickness_edgebanding2_type];
            tempThickProps.edgebanding3_bevel = fullSheetsSelectedMaterials[thickness_edgebanding3_bevel];
            tempThickProps.edgebanding3_description = fullSheetsSelectedMaterials[thickness_edgebanding3_description];
            tempThickProps.edgebanding3_price = fullSheetsSelectedMaterials[thickness_edgebanding3_price];
            tempThickProps.edgebanding3_radius = fullSheetsSelectedMaterials[thickness_edgebanding3_radius];
            tempThickProps.edgebanding3_size = fullSheetsSelectedMaterials[thickness_edgebanding3_size];
            tempThickProps.edgebanding3_square = fullSheetsSelectedMaterials[thickness_edgebanding3_square];
            tempThickProps.edgebanding3_type = fullSheetsSelectedMaterials[thickness_edgebanding3_type];
            tempThickProps.glued = fullSheetsSelectedMaterials[thickness_glued];
            tempThickProps.hinge_drilling = fullSheetsSelectedMaterials[thickness_hinge_drilling];
            tempThickProps.sheet_price = fullSheetsSelectedMaterials[thickness_sheet_price];
            tempThickProps.shelf_drilling = fullSheetsSelectedMaterials[thickness_shelf_drilling];
            tempThickProps.spray_coating = fullSheetsSelectedMaterials[thickness_spray_coating];
            tempThickProps.stock = fullSheetsSelectedMaterials[thickness_stock];
            tempThickProps.thicknessId = parseInt(i);

            console.log('-> A gasit thicknessu bun');
        }
    }
    fullSheetsThicknessProps[fullSheetsRowWithOpenSelection] = tempThickProps;
    fullSheetsConfig[fullSheetsRowWithOpenSelection].thicknessID = tempThickProps.thicknessId;
    console.log(tempThickProps);
    saveOrderConfig();
}

//  update the row inputs state to determine if the user can advance to the pricing page
export const changeFullSheetsInputCheck = (index, newState) =>{
    const currRowConfig = {...fullSheetsConfig[index]};
    currRowConfig.qtyState = newState;
    fullSheetsConfig[index] = currRowConfig;
    saveOrderConfig();
};


// Function to update the full sheets row config
export const changeFullSheetsRowConfig = (index, value) => {
    fullSheetsConfig[index].quantity = value;
    saveOrderConfig();
    return;
};

// Function to delete a row from the order
export const removeFullSheetsRow = () => {
    if (fullSheetsConfig !== -1) {
        // Use filter to create a new array without the specified element
        fullSheetsConfig = fullSheetsConfig.filter((_, index) => index !== fullSheetsRowWithOpenSelection);
    }
    if (fullSheetsThicknessProps !== -1) {
        // Use filter to create a new array without the specified element
        fullSheetsThicknessProps = fullSheetsThicknessProps.filter((_, index) => index !== fullSheetsRowWithOpenSelection);
    }
    saveOrderConfig();
};


// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| EDGING TAPE |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
let edgingTapeConfig = [];
let availableEdgingTape = [];
let edgingTapeRowWithOpenSelection = null;

export const setEdgingTapeRowWithOpenSelection = (rowID) =>{
    edgingTapeRowWithOpenSelection = rowID;
}

export const getEdgingTapeRowWithOpenSelection = () =>{
    return edgingTapeRowWithOpenSelection;
}

export const getEdgingTapeConfig = () => {
    return edgingTapeConfig;
}

export const getAvailableEdgingTape = async() =>{
    return availableEdgingTape;
}

//  an edging tape has been selected for a edging tape list row
export const selectedEdgingTapeForRow = (edgingTape) =>{
    var tempConfig = {
        name: edgingTape.name,
        description: edgingTape.description,
        size: edgingTape.size,
        decor_name: edgingTape.decor_name,
        quantity: null,
        qtyState: false,
    }
    edgingTapeConfig[edgingTapeRowWithOpenSelection] = tempConfig;
    saveOrderConfig();
}

// Function to update the edging tape row config
export const changeEdgingTapeRowConfig = (index, value) => {
    edgingTapeConfig[index].quantity = value;
    saveOrderConfig();
    return;
};

//  update the edging tape row inputs state to determine if the user can advance to the pricing page
export const changeEdgingTapeInputCheck = async(index, newState) =>{
    console.log('changing input check for:' + index, newState);
    const currRowConfig = {...edgingTapeConfig[index]};
    currRowConfig.qtyState = newState;
    edgingTapeConfig[index] = currRowConfig;
    saveOrderConfig();
};

// Function to push to the edging tape rows configuration array
export const addNewEdgingTapeRowInConfig = () => {
    // copy the material basic information
    var tempConfig = {
        name: null,
        description: null,
        size: null,
        decor_name: null,
        quantity: null,
        qtyState: false,
    }

    edgingTapeConfig = [...edgingTapeConfig, tempConfig];
    saveOrderConfig();
}

// Function to delete a row from the order
export const removeEdgingTapesRow = () =>{
    if (edgingTapeConfig !== -1) {
        // Use filter to create a new array without the specified element
        edgingTapeConfig = edgingTapeConfig.filter((_, index) => index !== edgingTapeRowWithOpenSelection);
    }
    saveOrderConfig();
}




// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-| ORDER COSTS |-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
// -/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/
//  request costs for the order
export const getOrderCosts = async() => {
    try {
        newBins = [];
        newOffcutsArray = [];
        mdfPanelsInOrder = [];
        currentBinSelected = null;
        groupCuts();
        const requestData = {
            rowsConfig: rowsConfig,
            fullSheetsConfig: fullSheetsConfig,
            edgetapeConfig: edgingTapeConfig,
            mdfPanelsInOrder: mdfPanelsInOrder,
        };
        const response = await axios.post(`${APIurl}/api/pricing`, requestData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Pricing API request finished');
        console.log(response.data);
        const pricingData = response.data;
        if(pricingData.Status === 'Ok'){
            if(pricingData.Data === 'Pricing calculated succesfully'){
                newBins = pricingData.bins;
                newOffcutsArray = pricingData.offcutsArray;
                return pricingData;
            }
        }else{
            return 'Server responded negative';
        }
    } catch (error) {
        console.error('Error fetching materials:', error);
        throw error; // Rethrow the error to handle it wherever this function is called
    }
}

export const getPanelsArray = async() =>{
    let panelsArray = [];
    //  create the panels array
    for(let i = 0; i < newBins.length; i++){
        const bin = newBins[i];
        let panelExistsInArray = panelsArray.find(panel => panel.product_code === bin.product_code && panel.thickness === bin.thickness);
        if(panelExistsInArray){
            panelExistsInArray.quantity = panelExistsInArray.quantity + 1;
        }else{
            const panelObj = {
                product_code: bin.product_code,
                thickness: bin.thickness,
                thicknessId: bin.thicknessID,
                decor_name: bin.decor_name,
                material_type: bin.material_type,
                brand: bin.brand,
                sheet_size: `${bin.panelLength + 10} x ${bin.panelWidth + 10} x ${bin.thickness} mm`,
                quantity: 1
            }
            panelsArray = [...panelsArray, panelObj];
        }
        console.log(`found bin for: ${bin.product_code}`);
        console.log(bin);
    }
    return panelsArray;
}

export const setOrderTotalPrice = (orderTotal, orderTotalVAT) =>{
    orderTotalPrice = orderTotal;
    orderTotalPriceVAT = orderTotalVAT;
}

export const getOrderTotalPrice = () =>{
    return orderTotalPrice;
}

export const getOrderTotalPriceVAT = () =>{
    return orderTotalPriceVAT;
}
