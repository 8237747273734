import React, { useState, useEffect, useContext, useRef } from 'react'
import './cartPage.css'
import Header from '../../header/header';
import { Cookies } from 'react-cookie';
import { CartContext } from '../cart/cartProvider';
import { APIurl } from '../../../ApiService';
import { useNavigate, Link} from 'react-router-dom';
import { IoCloseOutline } from "react-icons/io5";
import { GoDot } from "react-icons/go";
import { TbAlertHexagonFilled } from "react-icons/tb";
import autoAnimate from '@formkit/auto-animate';
import { IoReturnUpBack } from "react-icons/io5";
import Loader from "react-js-loader";
import axios from 'axios';


function CartPage() {
    const cookies = new Cookies();

    let { getCartItems, getCartItemCount, updateQuantityInCart, removeProductFromCart } = useContext(CartContext);
    const [cartItemsList, setCartItemsList] = useState([]);
    const [quantityInputs, setQuantityInputs] = useState([]);
    const [showPageLoader, setShowPageLoader] = useState(true);
    useEffect(() => {
        fetchCartItems();
    }, []);

    const [sectionDisplayed, setSectionDisplayed] = useState('cart');

    const fetchCartItems = async() => {
        const cartItems = await getCartItems();
        setCartItemsList([...cartItems]);
        console.log('Cart items:');
        console.log(cartItems);
        let newQuantityInputs = [];
        for(let i = 0; i < cartItems.length; i++){
            const newQuantityInput = {
                value: cartItems[i].quantity,
                alert: false,
                showUpdateBtn: false,
            };
            newQuantityInputs.push(newQuantityInput);
        }
        setQuantityInputs([...newQuantityInputs]);
        setShowPageLoader(false);
    }

    const handleQuantityChange = (itemId, inputValue) => {
        // Allow empty input
        if (inputValue === '') {
            let newQuantityInputs = quantityInputs;
            newQuantityInputs[itemId].alert = true;
            newQuantityInputs[itemId].showUpdateBtn = false;
            newQuantityInputs[itemId].value = inputValue;
            setQuantityInputs([...newQuantityInputs]);
            return;
        }
        // Validate integer input
        const isInteger = /^\d+$/.test(inputValue);
        if (isInteger) {
            const intValue = parseInt(inputValue, 10);
            if (intValue < 1) {
                let newQuantityInputs = quantityInputs;
                newQuantityInputs[itemId].alert = true;
                newQuantityInputs[itemId].showUpdateBtn = false;
                newQuantityInputs[itemId].value = inputValue;
                setQuantityInputs([...newQuantityInputs]);
                return;
            } else {
                if(cartItemsList[itemId].quantity !== intValue){
                    let newQuantityInputs = quantityInputs;
                    newQuantityInputs[itemId].alert = false;
                    newQuantityInputs[itemId].showUpdateBtn = true;
                    newQuantityInputs[itemId].value = intValue;
                    setQuantityInputs([...newQuantityInputs]);
                }else{
                    let newQuantityInputs = quantityInputs;
                    newQuantityInputs[itemId].alert = false;
                    newQuantityInputs[itemId].showUpdateBtn = false;
                    newQuantityInputs[itemId].value = intValue;
                    setQuantityInputs([...newQuantityInputs]);
                }
            }
        } else {
            // Invalid input
            let newQuantityInputs = quantityInputs;
            newQuantityInputs[itemId].alert = true;
            newQuantityInputs[itemId].showUpdateBtn = false;
            newQuantityInputs[itemId].value = inputValue;
            setQuantityInputs([...newQuantityInputs]);
            return;
        }
    };

    useEffect(() => {
        calculatePrice();
    }, [cartItemsList]);

    const [totalPrice, setTotalPrice] = useState(0);
    const calculatePrice = () => {
        let totalPrice = 0;
        for(let i = 0; i < cartItemsList.length; i++){
            totalPrice += cartItemsList[i].price * parseInt(quantityInputs[i].value, 10);
        }
        setTotalPrice(totalPrice.toFixed(2));
    }

    const updateQuantity = async(itemId, quantity) => {
        const newCartItems = await updateQuantityInCart(itemId, quantity);
        setCartItemsList([...newCartItems]);
        let newQuantityInputs = quantityInputs;
        newQuantityInputs[itemId].showUpdateBtn = false;
        setQuantityInputs([...newQuantityInputs]);
    }

    const removeProduct = async(itemId) => {
        const newCartItems = await removeProductFromCart(itemId);
        console.log('Cart items after remove in cart page:');
        console.log(newCartItems);
        setCartItemsList([...newCartItems]);
    }

    const cartListRef = useRef(null);
    useEffect(() => {
        if (cartListRef.current) {
            autoAnimate(cartListRef.current);
        }
    }, [cartListRef]);

    const placeholderImage = process.env.PUBLIC_URL + '/res/images/placeholder.jpg';

    const switchBetweenCartSection = (section) => {
        setSectionDisplayed(section);
    }

    const [deliveryForm, setDeliveryForm] = useState({
        delivery_type: 'delivery',
        name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        postcode: '',
        order_preferences: '',
    });

    const [deliveryFormAlerts, setDeliveryFormAlerts] = useState({
        name: false,
        phone: false,
        email: false,
        address: false,
        city: false,
        postcode: false,
        order_preferences: false,
    });

    useEffect(() => {
        const savedEmail = cookies.get('email'); // Retrieve email from cookies
        const savedPhone = cookies.get('phone'); // Retrieve phone from cookies

        // Update the state if cookies are found
        setDeliveryForm((prevState) => ({
            ...prevState,
            email: savedEmail || prevState.email,
            phone: savedPhone || prevState.phone,
        }));
    }, []);

    const changeDeliveryType = (type) => {
        setDeliveryForm((prevValues) => {
            if (prevValues.delivery_type !== type) {
                return {
                    ...prevValues,
                    delivery_type: type
                };
            }
            return prevValues;
        });
    };

    const handleDeliveryFormInputChange = (e, inputName) =>{
        const inputValue = e.target.value;
        setDeliveryForm({
            ...deliveryForm,
            [inputName]: inputValue
        });
    }

    const submitDeliveryForm = async() =>{
        // check each input
        const nameInput = deliveryForm.name;
        const phoneInput = deliveryForm.phone;
        const emailInput = deliveryForm.email;
        const addressInput = deliveryForm.address;
        const cityInput = deliveryForm.city;
        const postcodeInput = deliveryForm.postcode;
        const orderPreferencesInput = deliveryForm.order_preferences;
        const newAlerts = {
            name: false,
            phone: false,
            email: false,
            address: false,
            city: false,
            postcode: false,
            order_preferences: false,
        }
        console.log(nameInput.trim());
        if (nameInput.trim() == '') {
            newAlerts.name = true;
        }
        if (phoneInput.trim() == '' || phoneInput.length < 10) {
            newAlerts.phone = true;
        }
        if(emailInput.trim() == '' || !validateEmail(emailInput)){
            newAlerts.email = true;
        }
        if(addressInput.trim() == ''){
            newAlerts.address = true;
        }
        if(cityInput.trim() == ''){
            newAlerts.city = true;
        }
        if(postcodeInput.trim() == ''){
            newAlerts.postcode = true;
        }
        if(orderPreferencesInput.length > 255){
            newAlerts.order_preferences = true;
        }
        console.log(newAlerts);
        setDeliveryFormAlerts({...newAlerts});
        if(newAlerts.name == true || newAlerts.phone == true || newAlerts.email == true || newAlerts.address == true || newAlerts.city == true || newAlerts.postcode == true || newAlerts.order_preferences == true){
            return;
        }else{
            // get to checkout
            let orderData = {
                token: null,
                products: cartItemsList,
                delivery_info: deliveryForm,
            };
            const savedToken = cookies.get("authToken");
            if(savedToken){
                orderData.token = savedToken;
            }
            console.log('Making API request to checkout');
            const response = await axios.post(`${APIurl}/api/shopOrderSubmit`, orderData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseStatus = response.data.status;
            const dataMessage = response.data.data;
            //  verify the response
            if(responseStatus == 'error'){  //  if server gave error
                console.log('server error');
                return;
            }else{  //  if server responded ok
                if(dataMessage == 'Order ready for payment'){
                    const checkout_url = response.data.checkout_url;
                    window.location.href = checkout_url;
                    return;
                }else{
                    console.log('server error');
                    return;
                }
            }
        }
    }


    function validateEmail(email) {
        // Regular expression pattern for basic email validation
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    return (
        <div className='cart-page-section'>
            <Header />
            {sectionDisplayed === 'cart' ? (
                <>
                    <div className='shop-hero-container'>
                        <p>Your shopping cart</p>
                    </div>
                    <div className='cart-page-container'>
                        {showPageLoader === true ? (
                            <div className='cart-page-loader-section'>
                                <Loader type="spinner-default" bgColor='orange' color={'transparent'} size={50} />
                            </div>
                        ) : (
                            <>
                                <div className='cart-page-container-header'>
                                    <p>No. of products in your cart: {getCartItemCount()}</p>
                                    <p>Total: £{totalPrice}</p>
                                </div>
                                <div className='cart-page-container-list' ref={cartListRef}>
                                    {cartItemsList.length > 0 ? (cartItemsList.map((item, index) => (
                                        <div className='cart-page-container-list-item' key={'cart-page-container-list-item' + index}>
                                            <IoCloseOutline className='cart-item-delete-icon' onClick={() => removeProduct(index)}/>
                                            <div className='cart-item-header'>
                                                <div className='cart-item-header-image-container'>
                                                    <img src={item.image !== null ? item.image : process.env.PUBLIC_URL + '/res/images/placeholder.jpg'} 
                                                        alt={item.name}
                                                        onError={(e) => {
                                                            e.target.onerror = null; // Prevents looping
                                                            e.target.src = placeholderImage;
                                                        }}
                                                        className='cart-item-header-image'
                                                    />
                                                </div>
                                                <div className='cart-item-header-right'>
                                                    <div className='cart-item-header-top'>
                                                        <p style={{fontWeight: '500'}}>{item.productName}</p>
                                                        <div className='cart-item-header-quantity-div'>
                                                            <p className='cart-item-header-quantity-text'>Quantity:</p>
                                                            <input type='number' className='cart-item-header-quantity-input' value={quantityInputs[index]?.value} onChange={(e) => handleQuantityChange(index, e.target.value)}/>
                                                            {quantityInputs[index]?.alert === true ? (
                                                                <div className='cart-item-header-quantity-alert'>
                                                                    <TbAlertHexagonFilled className='cart-item-header-quantity-alert-icon'/>
                                                                    <p>Minimum quantity is 1</p>
                                                                </div>
                                                            ) : null}
                                                            {quantityInputs[index]?.showUpdateBtn === true ? (
                                                                <div className='cart-item-header-quantity-update-btn' onClick={() => updateQuantity(index, parseInt(quantityInputs[index].value, 10))}>
                                                                    <p>Update</p>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='cart-item-configuration'>
                                                        {item.baseOptions.length > 0 && item.baseOptions.map((option, index) => (
                                                            <div key={index} className='cart-item-configuration-element'>
                                                                <GoDot className='cart-item-configuration-element-icon' />
                                                                <p>{option.name}: {option.value}</p>
                                                            </div>
                                                        ))}
                                                        {item.squareMeterFixedOptions.length > 0 && item.squareMeterFixedOptions.map((option, index) => (
                                                            <div key={index} className='cart-item-configuration-element'>
                                                                <GoDot className='cart-item-configuration-element-icon' />
                                                                <p>{option.name}: {option.value}</p>
                                                            </div>
                                                        ))}
                                                        {item.squareMeterRangeOptions.length > 0 && item.squareMeterRangeOptions.map((option, index) => (
                                                            <div key={index} className='cart-item-configuration-element'>
                                                                <GoDot className='cart-item-configuration-element-icon' />
                                                                <p>{option.name}: {option.value}mm</p>
                                                            </div>
                                                        ))}
                                                        {item.fixedPercentageOptions.length > 0 && item.fixedPercentageOptions.map((option, index) => (
                                                            <div key={index} className='cart-item-configuration-element'>
                                                                <GoDot className='cart-item-configuration-element-icon' />
                                                                <p>{option.name}: {option.value}</p>
                                                            </div>
                                                        ))}
                                                        {item.rangePercentageOptions.length > 0 && item.rangePercentageOptions.map((option, index) => (
                                                            <div key={index} className='cart-item-configuration-element'>
                                                                <GoDot className='cart-item-configuration-element-icon' />
                                                                <p>{option.name}: {option.value}mm</p>
                                                            </div>
                                                        ))}
                                                        {item.additionalOptions.length > 0 && item.additionalOptions.map((option, index) => (
                                                            <div key={index} className='cart-item-configuration-element'>
                                                                <GoDot className='cart-item-configuration-element-icon' />
                                                                <p>{option.name}: {option.value}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='cart-item-price-div'>
                                                        <p className='cart-item-price-per-product'>Price per unit: £{item.price}</p>
                                                        <p className='cart-item-price'>Price: £{item.price * item.quantity}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))) : (
                                        <div className='cart-page-emty-cart-div'>
                                            <p className='cart-page-emty-cart-text'>Your cart is empty..</p>
                                            <Link to='/shop' className='cart-page-emty-cart-btn'>
                                                <p>Shop Now</p>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    {cartItemsList.length > 0 ? (
                        <div className='cart-page-to-checkout-btn' onClick={() => switchBetweenCartSection('deliveryInfo')}>
                            <p>Complete delivery info and purchase for £{totalPrice}</p>
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <div className='shop-hero-container'>
                        <p>Delivery Information</p>
                    </div>
                    <div className='cart-page-container'>
                        <div className='cart-page-container-header'>
                            <div className='cart-page-container-back-div' onClick={() => switchBetweenCartSection('cart')}>
                                <IoReturnUpBack  className='cart-page-container-header-back-icon'/>
                                <p>Back to cart items</p>
                            </div>
                        </div>
                        <div className='cart-page-container-header'>
                            <p>No. of products in your cart: {getCartItemCount()}</p>
                            <p>Total: £{totalPrice}</p>
                        </div>
                        <div className='cart-page-container-list' ref={cartListRef}>
                            <div className='cart-page-delivery-options-div'>
                                <div className={deliveryForm.delivery_type == 'delivery' ? 'cart-page-delivery-option-selected' : 'cart-page-delivery-option'} onClick={() => changeDeliveryType('delivery')}>
                                    <p className={deliveryForm.delivery_type == 'delivery' ? 'cart-page-delivery-option-text-selected' : 'cart-page-delivery-option-text'}>Delivery</p>
                                </div>
                                <div className={deliveryForm.delivery_type == 'pickup' ? 'cart-page-delivery-option-selected' : 'cart-page-delivery-option'} onClick={() => changeDeliveryType('pickup')}>
                                    <p className={deliveryForm.delivery_type == 'pickup' ? 'cart-page-delivery-option-text-selected' : 'cart-page-delivery-option-text'}>Personal Pickup</p>
                                </div>
                            </div>
                            <div className='cart-page-delivery-form'>
                                <div className='cart-page-delivery-form-row'>
                                    <div className='cart-page-delivery-form-input-row'>
                                        <div className='cart-page-delivery-label-container'>
                                            <p>Name: </p>
                                        </div>
                                        <div className='cart-page-delivery-input-container'>
                                            <input type='text' placeholder='Name' className='cart-page-delivery-input' value={deliveryForm.name} onChange={(e) => handleDeliveryFormInputChange(e, 'name')} style={{borderColor: deliveryFormAlerts.name == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}/>
                                            {deliveryFormAlerts.name === true ? (
                                                <div className='cart-page-delivery-form-alert'>
                                                    <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                    <p>Name is required</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='cart-page-delivery-form-row'>
                                    <div className='cart-page-delivery-form-input-row'>
                                        <div className='cart-page-delivery-label-container'>
                                            <p>Phone number: </p>
                                        </div>
                                        <div className='cart-page-delivery-input-container'>
                                            <input type='number' placeholder='Phone number' className='cart-page-delivery-input' value={deliveryForm.phone} onChange={(e) => handleDeliveryFormInputChange(e, 'phone')} style={{borderColor: deliveryFormAlerts.phone == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}/>
                                            {deliveryFormAlerts.phone === true ? (
                                                <div className='cart-page-delivery-form-alert'>
                                                    <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                    <p>Valid phone number is required</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='cart-page-delivery-form-row'>
                                    <div className='cart-page-delivery-form-input-row'>
                                        <div className='cart-page-delivery-label-container'>
                                            <p>Email: </p>
                                        </div>
                                        <div className='cart-page-delivery-input-container'>
                                            <input type='text' placeholder='Email' className='cart-page-delivery-input' value={deliveryForm.email} onChange={(e) => handleDeliveryFormInputChange(e, 'email')} style={{borderColor: deliveryFormAlerts.email == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}/>
                                            {deliveryFormAlerts.email === true ? (
                                                <div className='cart-page-delivery-form-alert'>
                                                    <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                    <p>Valid email address is required</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {deliveryForm.delivery_type === 'delivery' ? (
                                    <>
                                        <div className='cart-page-delivery-form-row'>
                                            <div className='cart-page-delivery-form-input-row'>
                                                <div className='cart-page-delivery-label-container'>
                                                    <p>City: </p>
                                                </div>
                                                <div className='cart-page-delivery-input-container'>
                                                    <input type='text' placeholder='City' className='cart-page-delivery-input' value={deliveryForm.city} onChange={(e) => handleDeliveryFormInputChange(e, 'city')} style={{borderColor: deliveryFormAlerts.city == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}/>
                                                    {deliveryFormAlerts.city === true ? (
                                                        <div className='cart-page-delivery-form-alert'>
                                                            <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                            <p>City is required</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart-page-delivery-form-row'>
                                            <div className='cart-page-delivery-form-input-row'>
                                                <div className='cart-page-delivery-label-container'>
                                                    <p>Address: </p>
                                                </div>
                                                <div className='cart-page-delivery-input-container'>
                                                    <input type='text' placeholder='Address' className='cart-page-delivery-input' value={deliveryForm.address} onChange={(e) => handleDeliveryFormInputChange(e, 'address')} style={{borderColor: deliveryFormAlerts.address == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}/>
                                                    {deliveryFormAlerts.address === true ? (
                                                        <div className='cart-page-delivery-form-alert'>
                                                            <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                            <p>Address is required</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart-page-delivery-form-row'>
                                            <div className='cart-page-delivery-form-input-row'>
                                                <div className='cart-page-delivery-label-container'>
                                                    <p>Postcode: </p>
                                                </div>
                                                <div className='cart-page-delivery-input-container'>
                                                    <input type='text' placeholder='Postcode' className='cart-page-delivery-input' value={deliveryForm.postcode} onChange={(e) => handleDeliveryFormInputChange(e, 'postcode')} style={{borderColor: deliveryFormAlerts.postcode == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}/>
                                                    {deliveryFormAlerts.postcode === true ? (
                                                        <div className='cart-page-delivery-form-alert'>
                                                            <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                            <p>Postcode is required</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart-page-delivery-form-row'>
                                            <div className='cart-page-delivery-form-input-row'>
                                                <div className='cart-page-delivery-label-container'>
                                                    <p>Order preferences: </p>
                                                </div>
                                                <div className='cart-page-delivery-input-container'>
                                                    <textarea placeholder='Order preferences' className='cart-page-delivery-textarea' value={deliveryForm.order_preferences} onChange={(e) => handleDeliveryFormInputChange(e, 'order_preferences')} style={{borderColor: deliveryFormAlerts.order_preferences == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}></textarea>
                                                    {deliveryFormAlerts.order_preferences === true ? (
                                                        <div className='cart-page-delivery-form-alert'>
                                                            <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                            <p>Maximum amount of characters is 250</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='cart-page-delivery-form-row'>
                                            <div className='cart-page-delivery-form-input-row'>
                                                <div className='cart-page-delivery-label-container'>
                                                    <p>Order preferences: </p>
                                                </div>
                                                <div className='cart-page-delivery-input-container'>
                                                    <textarea placeholder='Order preferences' className='cart-page-delivery-textarea' value={deliveryForm.order_preferences} onChange={(e) => handleDeliveryFormInputChange(e, 'order_preferences')} style={{borderColor: deliveryFormAlerts.order_preferences == true ? '#ff0030' : 'rgba(0, 0, 0, 0.1)'}}></textarea>
                                                    {deliveryFormAlerts.order_preferences === true ? (
                                                        <div className='cart-page-delivery-form-alert'>
                                                            <TbAlertHexagonFilled className='cart-page-delivery-form-alert-icon'/>
                                                            <p>Maximum amount of characters is 250</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cart-page-delivery-form-row'>
                                            <p>[Pickup address and time to be inserted]</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {cartItemsList.length > 0 ? (
                        <div className='cart-page-to-checkout-btn' onClick={() => submitDeliveryForm()}>
                            <p>Purchase for £{totalPrice}</p>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    )
}

export default CartPage